@import '~antd/dist/antd.css';
/*@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@600&family=Montserrat:wght@400;700&display=swap');*/
body {
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif, 'Baloo 2';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variant-ligatures: none;
  font-feature-settings: 'liga' 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

textarea:focus,
input:focus {
  outline: none;
}

.ant-table-thead > tr > th {
  padding: 12px !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
title {
  font-family: 'Baloo 2', sans-serif;
}

#text {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 16px;
}

#navigate {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 14.5px;
}

#text-bold {
  font-family: 'Montserrat', sans-serif;
  font-weight: bolder;
  font-size: 16px;
}
.opacity {
  opacity: 0.5;
}
.carousel_div {
  padding: 20px;
  background-color: #fff;
  box-shadow: 5px 5px 10px rgb(180, 180, 180);
  border-radius: 25px;
}
.carousel_div::after {
  content: '';
  position: absolute;
  border-left: 10px solid rgba(0, 0, 0, 0);
  border-right: 10px solid rgba(0, 0, 0, 0);
  border-bottom: 10px solid rgba(0, 0, 0, 0);
  border-top: 10px solid white;
  transform: translate(-50%, 0);
  display: block;
  /* top: 90%; */
  margin-top: 20px;
  left: 50%;
}
.font_sizes {
  font-size: 30px;
  color: #4f4ff6;
  margin: 0 12px;
}
.pricing_div {
  padding: 20px;
  border-radius: 20px;
  box-shadow: 7px 7px 7px rgb(194, 194, 194);
  background-color: #fff;
}

@media (min-width: 1100px) {
  .custom a {
    margin-left: 1px;
    margin-right: 1px;
    transform: scale(0.9);
  }
}
div.text-center li {
  justify-content: center;
}

.styles_faq-row-wrapper__3vA1D,
.styles_row-body__1NvUo,
.styles_faq-row__2YF3c,
.styles_row-title__1YiiY,
.styles_row-title-text__1MuhU {
  font-weight: bold;
}
